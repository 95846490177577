import { getInvest } from "@/api";
export default {
  data() {
    return {
      timeBar: 0,
      currentPage: 1,
      pages: 1,
      num: 10,
      total: 0,
      tableList: []
    };
  },
  mounted() {
    this.getInvestList();
  },
  methods: {
    seleTime(value) {
      this.timeBar = value;
    },
    getInvestList() {
      // pages 当前页  num一页显示条数
      getInvest({
        pages: this.pages,
        num: this.num
      }).then(res => {
        this.total = res.total;
        this.tableList = res.data;
        //   console.log(res)
      });
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.num);
    }
  }
};